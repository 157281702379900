import Input from "@/UI/Input/Input";
import s from "./Calculator.module.scss";
import SwapButton from "@/UI/SwapButton/SwapButton";
import Button from "@/UI/Button/Button";
// import TelegramSVG from "@/svg/Tg";
import Link from "next/link";
import { TELEGRAM_URL } from "@/utils/consts";
import Select from "@/UI/Select/Select";
import {
  ChangeEvent,
  FormEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createBid,
  fetchCities,
  fetchCourses,
  fetchDirections,
} from "@/api/services";
import { useRouter } from "next/router";
// import Cookies from "js-cookie";
import { IBid } from "@/api/interfaces";
// import { yCreateOrder, yOpenTg } from "@/utils/yandexMetrika";
// import P from "@/UI/P/P";
import { isCoin } from "@/utils/isCoin";
// import { roistatTelegramGoal } from "@/utils/roistatMetrik";
import { spaceDivision } from "@/utils/utils";
import LocationSVG from "@/svg/LocationSVG";
import CitySelect from "@/UI/CitySelect/CitySelect";
import { yCreateOrder } from "@/utils/yandexMetrika";
import { roistatOrder } from "@/utils/roistatMetrik";

const ExchangerBlock = () => {
  const router = useRouter();

  const [currentInput, setCurrentInput] = useState<"from" | "to">("from");
  const [city, setCity] = useState("msk");
  const [from, setFrom] = useState("USDTTRC");
  const [to, setTo] = useState("CASHRUB");
  const [amountFrom, setAmountFrom] = useState<string>("1");
  const [amountTo, setAmountTo] = useState<string>("0");
  const [telegram, setTelegram] = useState<string>("");
  const [tgError, setTgError] = useState<string>("");
  const [amountError, setAmountError] = useState<string>("");

  const handleAmountFrom = (value: string) => {
    setCurrentInput("from");
    setAmountFrom(value);
  };

  const handleAmountTo = (value: string) => {
    setCurrentInput("to");
    setAmountTo(value);
  };

  const { data: cities } = useQuery({
    queryKey: ["cities"],
    queryFn: async () => fetchCities(),
  });

  const { data: directions } = useQuery({
    queryKey: ["directions", city],
    queryFn: async () => await fetchDirections(city),
    enabled: !!city,
  });

  const { data: course } = useQuery({
    queryKey: ["course", from, to, city],
    queryFn: async () =>
      await fetchCourses({
        from_currency: from,
        to_currency: to === from ? "btc" : to,
        city: city,
      }),
    enabled: !!from && !!to && !!city,
  });

  const { mutateAsync, isLoading } = useMutation({
    mutationKey: ["create-bid"],
    mutationFn: (data: IBid) => createBid(data),
    onSuccess: (data) => {
      const bidId = data?.replace(/"/g, "");

      if (bidId?.length === 36) {
        if (location.pathname === "npm run ") {
          router.push("/tg-trade?id=" + bidId);
        } else {
          router.push("/trade?id=" + bidId);
        }
      } else
        alert("Во время создания заявки произошла ошибка, попробуйте позже!");
    },
  });

  useEffect(() => {
    directions?.filter.forEach(
      (item: { from_currency: any; to_currency: any }) => {
        if (item.from_currency?.code === from) {
          setTo(item.to_currency[0].code);
        }
      }
    );
  }, [from]);

  const query = useMemo(() => router.query, [router.query]);
  let fromV = query.cur_from;
  let toV = query.cur_to;
  let cityV = query.city;

  useEffect(() => {
    let fromV = query.cur_from;
    let toV = query.cur_to;
    let cityV = query.city;
    if (cityV && typeof cityV == "string") {
      setCity(cityV.toLowerCase());
    }

    if (fromV === "USDTTRC20") fromV = "USDTTRC";
    if (fromV === "USDTERC20") fromV = "USDTERC";
    if (fromV && typeof fromV == "string") {
      setFrom(fromV);
    }
    setTimeout(() => {
      if (toV === "USDTTRC20") toV = "USDTTRC";
      if (toV === "USDTERC20") toV = "USDTERC";
      if (toV && typeof toV == "string") {
        setTo(toV);
      }
      if (toV && typeof toV == "string" && isCoin(toV)) {
        setCurrentInput("to");
        setAmountTo("1");
      }
    }, 500);
  }, [query]);

  useEffect(() => {
    if (currentInput === "from") {
      if (amountFrom === "") setAmountTo("");
      else {
        if (course?.from_course === 1) {
          const v = Number(amountFrom) * course?.to_course;
          if (v) setAmountTo(String(v));
        } else {
          const v = Number(amountFrom) / course?.from_course;
          if (v) setAmountTo(String(v));
        }
      }
    }

    if (currentInput === "to") {
      if (amountTo === "") setAmountFrom("");
      else {
        if (course?.from_course === 1) {
          const v = Number(amountTo) / course?.to_course;
          if (v) setAmountFrom(String(v));
        } else {
          const v = Number(amountTo) * course?.from_course;
          if (v) setAmountFrom(String(v));
        }
      }
    }
  }, [amountFrom, amountTo, currentInput, course]);

  useEffect(() => {
    if (directions) {
      if (
        !directions?.currencies_from.some((item: any) => item?.code === from)
      ) {
        setFrom(directions?.currencies_from[0].code);
      }
      if (
        !directions?.filter[0].to_currency.some(
          (item: any) => item?.code === to
        )
      ) {
        directions?.filter.map((item: any) => {
          if (item.from_currency.code === from) {
            setTo(item.to_currency[0].code);
          }
        });
      }
    }
  }, [directions, city]);

  const swap = () => {
    const tempCoin = from;
    setFrom(to);
    setTimeout(() => setTo(tempCoin));
    if (from.includes("CASH")) {
      handleAmountFrom(amountTo);
    } else {
      handleAmountTo(amountFrom);
    }
  };

  const checkTelegram = () => {
    if (!telegram) {
      return "Поле телеграм обязательное";
    }
    if (telegram[0] !== "@") {
      return "Телеграм должен начинаться с @";
    }
    if (telegram.length < 6) {
      return "Минимальная длина телеграм 5 символов";
    }
    if (telegram.length > 33) {
      return "Максимальная длина телеграм 32 символа";
    }

    return false;
  };

  const submit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const tgError = checkTelegram();
    if (tgError) {
      setTgError(tgError);
    } else {
      setTgError("");
    }
    if (!amountFrom || parseFloat(amountFrom) === 0) {
      setAmountError("Значение не должно быть равно 0");
    } else {
      setAmountError("");
    }
    // if (parseFloat(amountFrom) < course.min_from || parseFloat(amountFrom) > course.max_from) {
    //   setAmountError(`Значение должно быть от ${spaceDivision(course.min_from)} до ${spaceDivision(course.max_from)}`)
    // } else {
    //   setAmountError("")
    // }
    if (!tgError && !amountError) {
      yCreateOrder();
      roistatOrder();
      const referrer =
        typeof window !== "undefined"
          ? sessionStorage.getItem("referrer")
          : null;
      const utm_source = sessionStorage.getItem("utm_source");
      const utmid = sessionStorage.getItem("utmid")?.split(":");
      const utm_medium = sessionStorage.getItem("utm_medium");
      const roistat_visit =
        localStorage?.getItem("roistat_first_visit") ||
        localStorage?.getItem("roistat_visit") ||
        "";
      await mutateAsync({
        user_name: "user",
        user_mail: "",
        user_telegram: telegram,
        user_phone: "",
        city_code: city,
        currency_from: from,
        currency_to: to,
        amount_from: amountFrom,
        amount_to: amountTo,
        course_from: course?.from_course,
        course_to: course?.to_course,
        key: "54d54a126a",
        utmid: utm_source || referrer || null,
        ref_id: utmid?.at(1) ? utmid[1] : "",
        utm_medium: utm_medium ? utm_medium : "",
        utm_source: utm_source ? utm_source : "",
        roistat_visit,
      });
    }
  };

  useEffect(() => {
    if (telegram && telegram[0] !== "@") {
      setTelegram("@" + telegram);
    }
  }, [telegram]);

  return (
    <div className={s.block}>
      <div className={s.title}>
        {/* <CitySelect /> */}
        <Select
          key="city"
          options={cities?.map((city: any) => ({
            label: city.name_ru,
            code: city.code,
          }))}
          setValue={setCity}
          value={city}
          type="city"
          defaultValue={{ label: "Москва", code: "msc" }}
        />
      </div>
      <form onSubmit={submit} className={s.form}>
        <div className={s.exchanger}>
          <div style={{ zIndex: "14" }} className={s.line}>
            <Input
              name="from"
              label="Отдаете"
              inputMode="decimal"
              pattern="[0-9.,]*"
              value={amountFrom}
              onChange={handleAmountFrom}
              min={course?.min_from}
              max={course?.max_from}
              selectName={from}
            />

            {amountError && <span className={s.error}>{amountError}</span>}
            <div className={s.select}>
              <Select
                key="from"
                // options={[
                //   {
                //     label: "USDT",
                //     code: "EF",
                //   },
                //   {
                //     label: "BTC",
                //     code: "EsF",
                //   },
                //   {
                //     label: "ETH",
                //     code: "EsFs",
                //   },
                // ]}
                options={directions?.currencies_from.map((option: any) => ({
                  label: option.name,
                  code: option.code,
                }))}
                setValue={setFrom}
                value={from}
                defaultValue={{ label: "USDTTRC", code: "USDTTRC" }}
              />
            </div>
          </div>
          <SwapButton onClick={swap} />
          <div style={{ zIndex: "13" }} className={s.line}>
            <Input
              name="to"
              label="Получаете"
              inputMode="decimal"
              pattern="[0-9.,]*"
              value={amountTo}
              onChange={handleAmountTo}
              min={course?.min_to}
              max={course?.max_to}
              selectName={to}
            />
            <div className={s.select}>
              <Select
                key="to"
                // options={[
                //   {
                //     label: "USDT",
                //     code: "EF",
                //   },
                //   {
                //     label: "BTC",
                //     code: "EsF",
                //   },
                //   {
                //     label: "ETH",
                //     code: "EsFs",
                //   },
                // ]}
                options={
                  directions?.filter
                    .map(
                      (option: any) =>
                        option.from_currency.code === from &&
                        option.to_currency.map((option: any) => ({
                          label: option.name,
                          code: option.code,
                        }))
                    )
                    .filter(Boolean)[0]
                }
                setValue={setTo}
                value={to}
                defaultValue={{ label: "RUB", code: "CASHRUB" }}
              />
            </div>
          </div>
        </div>
        <div className={s.telegram}>
          <Input
            value={telegram}
            onChange={setTelegram}
            name="telegram"
            label="Телеграм"
            placeholder="Введите телеграм"
          />
          {tgError && <span className={s.error}>{tgError}</span>}
        </div>

        <div className={s.submit}>
          {/* <div className={s.button}> */}
          <Button>ОБМЕНЯТЬ</Button>
          {/* </div> */}
          {/* <div className={s.contact}> */}
          {/* <button onClick={click1}> */}
          {/* <TelegramSVG /> */}
          {/* Телеграм */}
          {/* </button> */}
          {/* </div> */}
        </div>
      </form>
    </div>
  );
};

export default ExchangerBlock;
